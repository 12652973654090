import React from 'react';

import AppWrapper from '../../components/appWrapper/appWrapper';
import useScrollToLocation from '../../hooks/useScrollToLocation';
import BusinessBenefits from './components/businessBenefits/businessBenefits';
import { BusinessCategories } from './components/businessCategories/businessCategories';
import Faqs from './components/faqs/faqs';
import HeroSection from './components/heroSection/heroSection';
import { PaidFeatures } from './components/paidFeatures/paidFeatures';
import { SignUpProcess } from './components/signUpProcess/signUpProcess';
import { TopFeatures } from './components/topFeatures/topFeatures';

export const BusinessHome = () => {
  useScrollToLocation();

  return (
    <AppWrapper>
      <HeroSection />
      <TopFeatures />

      <BusinessBenefits />
      <PaidFeatures />
      <SignUpProcess />
      <BusinessCategories />

      <Faqs />
    </AppWrapper>
  );
};
