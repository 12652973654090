import styled from 'styled-components';

export const HeroSectionContainer = styled.section`
  position: relative;

  background: ${({ theme }) => theme.core.colors.secondary.yellow.medium};
  height: 700px;

  img {
    display: none;
  }

  > div {
    height: 100%;

    margin: auto;
  }

  @media ${({ theme }) => `${theme.core.breakpoints.s}`} {
    display: flex;

    overflow: hidden;

    height: 600px;

    img {
      display: block;

      position: absolute;

      bottom: 0;
      height: 100%;
    }
  }
  @media ${({ theme }) => `${theme.core.breakpoints.xl}`} {
    img {
      position: absolute;

      left: 250px;

      bottom: initial;
    }
  }
`;

export const HeroOverviewContainer = styled.div`
  position: absolute;
  bottom: 0;
  margin: ${({ theme }) => theme.core.sizing[2]}px;
  margin-bottom: 0;

  @media ${({ theme }) => `${theme.core.breakpoints.s}`} {
    display: flex;

    width: 60%;

    margin: 0;
  }
`;

export const HeroContent = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.core.sizing[4]}px;

  border-radius: ${({ theme }) => theme.core.border.element.radius}px
    ${({ theme }) => theme.core.border.element.radius}px 0 0;

  background: ${({ theme }) => theme.core.colors.greyscale.white}e6;

  padding: ${({ theme }) => theme.core.sizing[5]}px;

  h1 {
    margin: 0;

    font-family: ${({ theme }) => theme.core.fontFamily.jost};
    font-size: ${({ theme }) => theme.core.fontSizes.xxxl}px;
  }

  h2 {
    margin: 0;

    font-size: ${({ theme }) => theme.core.fontSizes.l}px;
  }

  > p {
    margin: 0;
  }

  button {
    cursor: pointer;
  }

  @media ${({ theme }) => `${theme.core.breakpoints.s}`} {
    max-width: 700px;
    min-width: 630px;

    margin-left: -40px;
  }

  @media ${({ theme }) => `${theme.core.breakpoints.xl}`} {
  }
`;

export const HighlightContent = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.core.sizing[2]}px;

  background: ${({ theme }) => theme.core.colors.greyscale.light};

  padding: ${({ theme }) => theme.core.sizing[2]}px;

  h3 {
    margin: 0;

    font-family: ${({ theme }) => theme.core.fontFamily.jost};
    font-size: ${({ theme }) => theme.core.fontSizes.l}px;

    color: ${({ theme }) => theme.core.colors.primary.green.medium};
  }

  p {
    margin: 0;
  }

  > span {
    align-self: flex-end;

    strong {
      font-size: ${({ theme }) => theme.core.fontSizes.xl}px;
    }
  }
`;

export const ActionContainer = styled.div`
  display: flex;
  align-self: flex-start;
  gap: ${({ theme }) => theme.core.sizing[2]}px;
`;
