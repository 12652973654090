export const SIGN_UP_PROCESS_CONFIG = [
  {
    title: 'Get started for free',
    description: 'Get started with Pata for free. It’s quick and easy, and no credit card required',
  },
  {
    title: 'Set up your business',
    description:
      'Set up your business in no time. Just add your services, team members, and availability to accept bookings',
  },
  {
    title: 'Enable online payments',
    description:
      'Enable online payments to make it easier for clients to book and pay. This reduces cancellations and no-shows, giving you the peace of mind you deserve',
  },
  {
    title: 'Boost your visibility & grow your client base',
    description:
      'Boost your visibility via Pata Marketplace to attract new clients and get found by pet owners actively looking for services like yours. Whether you are just getting started, growing or established, we help you stand out',
  },
];
