import { WidthContainer } from '@pata-app/core';

import React from 'react';

import SectionContainer from '../../../../components/sectionContainer/sectionContainer';
import { TextWithHighlight } from '../../../../components/textWithHighlight/textWithHighlight';
import { FeatureTile } from './featureTile/featureTile';
import {
  FeatureHighlight,
  FeatureTileContent,
  FeatureTilesContainer,
  MessageCount,
  PercentageContainer,
} from './featureTile/featureTile.styled';
import { VatExclusion } from './paidFeatures.styled';

const TopFeatureTitle = () => {
  return (
    <TextWithHighlight>
      <h2>
        <span>Optional </span>
        paid features
      </h2>

      <p>No hidden fees. Pay as you go. Zero commitment.</p>
    </TextWithHighlight>
  );
};

export function PaidFeatures() {
  return (
    <WidthContainer noBackground>
      <SectionContainer title={TopFeatureTitle}>
        <FeatureTilesContainer>
          <FeatureTile alignment="left" image={`${process.env.CDN_BASE_URL}/images/image-person-on-iphone.jpeg`}>
            <FeatureTileContent>
              <h3>Notifications</h3>
              <p>
                Notify your clients about their appointments, helping them stay informed and reducing no-shows by
                sending timely reminders.
              </p>
              <FeatureHighlight>
                <MessageCount>
                  <span>150</span>
                  <span>FREE</span>
                </MessageCount>
                <div>
                  <span>
                    monthly texts, unlimited email notifications, £0.05<sup>*</sup>
                  </span>
                  <span>per additional text</span>
                </div>
              </FeatureHighlight>
            </FeatureTileContent>
          </FeatureTile>
          <FeatureTile
            alignment="right"
            image={`${process.env.CDN_BASE_URL}/images/image-person-paying-with-bank-card.jpeg`}
          >
            <FeatureTileContent>
              <h3>Online Payments</h3>
              <p>
                Process online payments securely delivering a seamless checkout experience and protecting your business
                by taking up-front payments.
              </p>
              <FeatureHighlight>
                <PercentageContainer>%</PercentageContainer>
                <div>
                  <span>
                    Payment Processing fee of 2.99% <sup>*</sup> + £0.45<sup>*</sup>
                  </span>
                  <span>per online transaction</span>
                </div>
              </FeatureHighlight>
            </FeatureTileContent>
          </FeatureTile>
          <VatExclusion>
            <sup>
              <strong>*</strong>
            </sup>
            All fees are subject to VAT
          </VatExclusion>
        </FeatureTilesContainer>
      </SectionContainer>
    </WidthContainer>
  );
}
