import { Method } from 'axios';

import { endpoints, methods, request } from '../lib/api';
import { BusinessSearchItem } from '../types/business.types';

export async function getBusinessSearch(businessType: string): Promise<BusinessSearchItem[]> {
  const data = await request({
    endpoint: endpoints.businessSearch,
    method: methods.POST as Method,
    body: { country: 'gb', radius: 5, searchTerm: businessType, searchTermType: 'category' },
  });

  return data.data.data;
}
