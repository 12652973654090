import { Image, WidthContainer } from '@pata-app/core';

import React from 'react';
import { useTheme } from 'styled-components';

import AppWrapper from '../../components/appWrapper/appWrapper';
import TextAndImageSection from '../../components/textAndImageSectionWithBackground/textAndImageSectionWithBackground';
import { MissionTileConfig } from './aboutUs.config';
import {
  AboutUsDescription,
  CharityContainer,
  ContactTileContainer,
  HeroSectionContainer,
  SectionHeader,
} from './aboutUs.styled';
import MissionTile from './components/missionTile/missionTile';
import OurStory from './components/ourStory/ourStory';

const AboutUs = () => {
  const theme = useTheme();

  return (
    <AppWrapper>
      <HeroSectionContainer>
        <div>
          <Image src={`${process.env.CDN_BASE_URL}/images/image-upsidedown-dogs.webp`} alt="" />
        </div>
        <h1>ABOUT US</h1>
        <h2>We are your Pet Marketplace</h2>
      </HeroSectionContainer>

      <WidthContainer noBackground>
        <AboutUsDescription>
          At Pata, pets come first in everything we do. Our app was designed by pet parents, for pet parents, with the
          aim of simplifying pet parenting and spreading joy throughout your journey together. Launched in 2023, we saw
          a need for an all-in-one digital solution that unites pet resources, services, advice and community. We
          believe pet parenthood should be easier, less stressful, and full of moments that make tails wag!
        </AboutUsDescription>

        <SectionHeader>Our Mission</SectionHeader>

        <ContactTileContainer>
          {MissionTileConfig.map((missionTile) => (
            <MissionTile
              key={missionTile.title}
              imageUrl={missionTile.imageUrl}
              title={missionTile.title}
              description={missionTile.description}
            />
          ))}
        </ContactTileContainer>
      </WidthContainer>

      <TextAndImageSection
        imageSrc={`${process.env.CDN_BASE_URL}/images/image-ipad-app.webp`}
        backgroundColour={theme.core.colors.secondary.yellow.medium as string}
      >
        <OurStory />
      </TextAndImageSection>
    </AppWrapper>
  );
};

export default AboutUs;
