import { WidthContainer } from '@pata-app/ui-document';

import React, { Fragment } from 'react';

import Faq from './components/faq';
import { faqsConfig } from './faq.config';
import { FaqsContainer, FaqsSection } from './faqs.styled';

const Faqs = () => {
  return (
    <WidthContainer>
      <FaqsSection id="faq">
        <h2>
          <span>Have a question?</span>

          <span>
            See our <span>frequently asked questions</span>
          </span>
        </h2>
        <FaqsContainer>
          {faqsConfig.map((faq, index) => (
            <Fragment key={faq.question}>
              <Faq question={faq.question} answer={faq.answer} />
              {index + 1 < faqsConfig.length && <hr />}
            </Fragment>
          ))}
        </FaqsContainer>
      </FaqsSection>
    </WidthContainer>
  );
};

export default Faqs;
