import { Image } from '@pata-app/core';
import { WidthContainer } from '@pata-app/ui-document';
import { Button } from '@pata-app/ui-form';

import React from 'react';

import { TextWithHighlight } from '../../../../components/textWithHighlight/textWithHighlight';
import { ActionContainer, HeroContent, HeroOverviewContainer, HeroSectionContainer } from './heroSection.styled';

const HeroSection = () => {
  const handleSignUpClick = () => {
    window.location.href = `${process.env.PARTNERS_BASE_URL}/sign-up`;
  };

  return (
    <HeroSectionContainer>
      <Image
        src={`${process.env.CDN_BASE_URL}/images/image-partner-app-grid.png`}
        alt="Preview of Pata Partner app on iPad"
        height={2000}
        width={2000}
      />

      <WidthContainer noBackground>
        <HeroOverviewContainer>
          <HeroContent>
            <TextWithHighlight>
              <h1>
                The fastest growing, <span>subscription-free </span>
                platform for pet care businesses
              </h1>
            </TextWithHighlight>
            <p>
              Simplify your operations, boost your visibility, and grow your client base with Pata.
              <br />
              Join our thriving marketplace to attract new clients, whether you offer pet grooming, pet training, dog
              walking, or any other pet service.
            </p>
            <p>Join for free and transform your pet care business today.</p>

            <ActionContainer>
              <Button onClick={handleSignUpClick}>Join for Free</Button>
            </ActionContainer>
          </HeroContent>
        </HeroOverviewContainer>
      </WidthContainer>
    </HeroSectionContainer>
  );
};

export default HeroSection;
