export const TOP_FEATURES_TITLES_CONFIG = [
  {
    title: 'Appointment management',
    description: "Say goodbye to pen and paper. Pata's simple, easy, yet powerful calendar, simplifies your day-to-day",
    icon: 'Calendar',
  },
  {
    title: 'Online bookings',
    description: 'Take online bookings securely delivering a seamless checkout experience to your clients 24/7',
    icon: 'Smartphone',
  },
  {
    title: 'Marketplace',
    description: 'List your business on Pata to attract new clients on the fastest growing pet care marketplace',
    icon: 'ShoppingCart',
  },
  {
    title: 'No-shows',
    description: 'Secure bookings with up front payments. Have full control on the amount and which services apply',
    icon: 'NoShow',
  },
  {
    title: 'Notifications & reminders',
    description: 'Keep clients informed of any appointment changes and reduce no-shows with timely reminders',
    icon: 'Notification',
  },
  {
    title: 'Team',
    description: 'Easily manage your team, their shifts, time off, and the services they offer',
    icon: 'Team',
  },
];
