import ActionMenu from './action-menu.svg';
import Arrow from './arrow.svg';
import Boarding from './boarding.svg';
import Bold from './bold.svg';
import Breeder from './breeder.svg';
import BulletList from './bullet-list.svg';
import Calendar from './calendar.svg';
import Cash from './cash.svg';
import Cat from './cat.svg';
import Catalogue from './catalogue.svg';
import Clear from './clear.svg';
import Client from './client.svg';
import Close from './close.svg';
import ClosedDate from './closed-date.svg';
import Daycare from './daycare.svg';
import Delete from './delete.svg';
import DogWalking from './dog-walking.svg';
import Edit from './edit.svg';
import Email from './email.svg';
import Empty from './empty.svg';
import Filters from './filters.svg';
import Google from './google.svg';
import Grooming from './grooming.svg';
import HorizontalRule from './horizontal-rule.svg';
import Italic from './italic.svg';
import LineBreak from './line-break.svg';
import Media from './media.svg';
import Membership from './membership.svg';
import NoShow from './no-show.svg';
import Note from './note.svg';
import Notification from './notification.svg';
import OrderedList from './ordered-list.svg';
import PataLogo from './pata-logo.svg';
import PetCremation from './pet-cremation.svg';
import PetHydrotheraphy from './pet-hydrotheraphy.svg';
import PetService from './pet-service.svg';
import PetStore from './pet-store.svg';
import PetTransportation from './pet-transportation.svg';
import Pin from './pin.svg';
import QuoteBlock from './quote-block.svg';
import Sales from './sales.svg';
import Search from './search.svg';
import Settings from './settings.svg';
import ShoppingCart from './shopping-cart.svg';
import Sitting from './sitting.svg';
import Smartphone from './smartphone.svg';
import Sort from './sort.svg';
import Strikethrough from './strikethrough.svg';
import Tax from './tax.svg';
import Team from './team.svg';
import Terminal from './terminal.svg';
import TextMessage from './text-message.svg';
import Tick from './tick.svg';
import Training from './training.svg';
import Underline from './underline.svg';
import Undo from './undo.svg';
import User from './user.svg';
import Vet from './vet.svg';
import Workplace from './workplace.svg';

// import { ReactComponent as Other } from './other.svg';

export const Icons: { [key: string]: React.FC } = {
  Client,
  User,
  Calendar,
  Sales,
  Team,
  Settings,
  Catalogue,
  Workplace,
  ActionMenu,
  Arrow,
  Cat,
  Close,
  Delete,
  Edit,
  Notification,
  Search,
  Empty,
  PataLogo,
  Filters,
  Bold,
  Strikethrough,
  Italic,
  Underline,
  Clear,
  BulletList,
  OrderedList,
  QuoteBlock,
  Undo,
  ClosedDate,
  Membership,
  Note,
  Sort,
  Grooming,
  DogWalking,
  Boarding,
  Daycare,
  Breeder,
  PetStore,
  Sitting,
  Training,
  LineBreak,
  HorizontalRule,
  Tax,
  Media,
  Terminal,
  // Other,
  Cash,
  TextMessage,
  Email,
  Google,
  Pin,
  Tick,
  PetService,
  Vet,
  NoShow,
  PetTransportation,
  ShoppingCart,
  Smartphone,
  PetCremation,
  PetHydrotheraphy,
};
