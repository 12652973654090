import React from 'react';

import AppWrapper from '../../components/appWrapper/appWrapper';
import useScrollToLocation from '../../hooks/useScrollToLocation';
import BusinessPartner from './components/businessPartner/businessPartner';
import BusinessCarousel from './components/businessesCarousel/businessCarousel';
import HeroSection from './components/heroSection/heroSection';
import HighlightsSection from './components/highlightsSection/highlightsSection';

const Home = () => {
  useScrollToLocation();

  return (
    <AppWrapper>
      <HeroSection />
      <BusinessCarousel />
      <HighlightsSection />
      <BusinessPartner />
    </AppWrapper>
  );
};

export default Home;
