import { Image, WidthContainer } from '@pata-app/core';
import { Button } from '@pata-app/ui-form';

import React from 'react';
import { Link } from 'react-router-dom';

import { BusinessPartnersContainer, ImageContainer, SectionContent } from './businessPartner.styled';

const BusinessPartners = () => {
  return (
    <BusinessPartnersContainer id="newsletter">
      <WidthContainer>
        <SectionContent>
          <h2>
            <span>Are you a </span>
            <span> </span>
            <span>pet business?</span>
          </h2>

          <p>
            Join the fastest growing, subscription-free platform for pet businesses, designed to save you time and money
            with online bookings, payment protection, and easy appointment management.
          </p>
          <p>
            Be discovered by thousands of pet owners actively searching for services, and grow your business
            effortlessly on a platform that lets you focus on delivering the great pet care our pets deserve.
          </p>

          <Link to="/business">
            <Button>Find Out More</Button>
          </Link>
        </SectionContent>
        <ImageContainer>
          <Image src={`${process.env.CDN_BASE_URL}/images/image-ipad-app.webp`} alt="iPad showing Pata Pet website" />
        </ImageContainer>
      </WidthContainer>
    </BusinessPartnersContainer>
  );
};

export default BusinessPartners;
