import { WidthContainer } from '@pata-app/core';

import React from 'react';

import AppWrapper from '../../components/appWrapper/appWrapper';
import html from './privacy.html';

const PrivacyPolicy = () => {
  return (
    <AppWrapper>
      <WidthContainer noBackground>
        <div style={{ margin: '20px 0' }}>
          <div dangerouslySetInnerHTML={{ __html: html }} />
        </div>
      </WidthContainer>
    </AppWrapper>
  );
};

export default PrivacyPolicy;
