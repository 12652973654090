import { Image, WidthContainer } from '@pata-app/core';

import React from 'react';

import { HeroContentContainer, HeroHeading, HeroSectionContainer, HeroSubHeading } from './heroSection.styled';
import SearchBox from './searchBox/searchBox';

const HeroSection = () => {
  return (
    <HeroSectionContainer>
      <Image width={2500} height={2000} src="https://static.patacdn.net/images/image-hero-man-holding-pug.png" />

      <WidthContainer noBackground>
        <HeroContentContainer>
          <HeroHeading>
            <span>Ready. Set.</span>
            <span>Book in your Pet.</span>
          </HeroHeading>
          <HeroSubHeading>Discover local pet services tailored to your needs</HeroSubHeading>
          <SearchBox />
        </HeroContentContainer>
      </WidthContainer>
    </HeroSectionContainer>
  );
};

export default HeroSection;
