import { Image } from '@pata-app/core';
import { Icon } from '@pata-app/icons';
import { WidthContainer } from '@pata-app/ui-document';

import React from 'react';
import { useTheme } from 'styled-components';

import { TextWithHighlight } from '../../../../components/textWithHighlight/textWithHighlight';
import { businessBenefits } from './businessBenefits.config';
import { BusinessBenefit, BusinessBenefitsContainer, BusinessBenefitsContent } from './businessBenefits.styled';

const BusinessBenefits = () => {
  const theme = useTheme();
  return (
    <BusinessBenefitsContainer>
      <Image
        src={`${process.env.CDN_BASE_URL}/images/image-dog-jumping-3.jpeg`}
        alt="Dogs running"
        height={1500}
        width={2500}
      />
      <WidthContainer noBackground>
        <BusinessBenefitsContent>
          <h2>
            <TextWithHighlight>No subscriptions,</TextWithHighlight>
            <TextWithHighlight>
              <span>
                no hidden costs, <span>just Free</span>
              </span>
            </TextWithHighlight>
          </h2>

          <p>
            Essential features for free with no subscriptions or hidden fees.
            <br />
            The only subscription-free platform for pet businesses
          </p>

          <ul>
            {businessBenefits.map((benefit) => (
              <BusinessBenefit key={benefit.title}>
                <Icon type="Tick" fill={theme.core.colors.primary.green.medium} size="18px" />
                <strong>{benefit.title}</strong>
                <p>{benefit.description}</p>
              </BusinessBenefit>
            ))}
          </ul>
        </BusinessBenefitsContent>
      </WidthContainer>
    </BusinessBenefitsContainer>
  );
};

export default BusinessBenefits;
