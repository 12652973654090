export const businessBenefits = [
  {
    title: 'Unlimited team members',
    description: 'Manage as many team members as your business needs at no extra cost',
  },
  {
    title: 'Unlimited clients and email notifications',
    description: 'Keep all your client information and enjoy unlimited email notifications, making communication easy',
  },
  {
    title: 'Unlimited locations/workplaces/branches',
    description: 'Add all your business locations, branches, or workplaces without restrictions or additional charges',
  },
  { title: 'Unlimited appointments', description: 'Book and manage an unlimited number of appointments' },
];
