import styled from 'styled-components';

export const SectionContainerContainer = styled.section`
  margin: 70px 16px;

  h2 {
    margin: 0;
    font-size: ${({ theme }) => theme.core.fontSizes.xxl}px;
  }

  @media ${({ theme }) => `${theme.core.breakpoints.l}`} {
    margin: 70px 0;
  }
`;
